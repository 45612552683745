import { createLogger, createStore } from "vuex";
import Auth from "./Auth";
import Departments from "./Departments";
import Fields from "./Fields";
import Positions from "./Positions";
import Roles from "./Roles";
import Statuses from "./Statuses";
import Teams from "./Teams";
import Users from "./Users";

export default createStore({
  modules: {
    Auth,
    Departments,
    Fields,
    Positions,
    Roles,
    Statuses,
    Teams,
    Users,
  },
  plugins: [createLogger()],
});
