<template>
  <base-form-page
    class="position-form"
    :page-title="pageTitle"
    :card-title="cardTitle"
    :store-method="storeRecord"
    :is-changed="isChanged"
    :breadcrumbs="breadcrumbs"
    :id="id"
  >
    <div class="pl-lg-4">
      <div class="row">
        <div class="col">
          <base-input
            name="name"
            type="name"
            label="Название"
            placeholder="Название"
            v-model="model.name"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <DepartmentSelect
            name="department_id"
            type="department_id"
            placeholder="Отдел"
            v-model="model.department_id"
            clearable
          ></DepartmentSelect>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <TeamSelect
            name="team_id"
            type="team_id"
            placeholder="Команда"
            v-model="model.team_id"
            clearable
          ></TeamSelect>
        </div>
      </div>
    </div>
  </base-form-page>
</template>

<script>
import BaseFormPage from '../../components/BaseFormPage'
import DepartmentSelect from '../../components/DepartmentSelect/DepartmentSelect'
import TeamSelect from '../../components/TeamSelect/TeamSelect'
import { useBaseForm } from '@/services/useBaseForm'
import { useStore } from 'vuex'
import { ref } from 'vue'

const config = {
  createTitle: "Создание позиции",
  editTitle: "Редактирование позиции",
  storePrefix: "Positions",
  successMessage: "Позиция сохранена",
  breadCrumbs: {
    "/positions": "Позиции",
  },
};

export default {
  name: "PositionForm",
  components: { BaseFormPage, DepartmentSelect, TeamSelect },
  setup() {
    const store = useStore();

    const teams = ref([]);
    store.dispatch("Teams/fetchMany", { limit: 100 }).then(({ data }) => {
      teams.value = data;
    });

    const map = (data) => ({
      name: data.name,
      team_id: data.team_id,
      department_id: data.department_id,
    });

    return {
      ...useBaseForm({ ...config, map }),
      teams,
    };
  },
};
</script>

<style scoped></style>
