<template>
  <div class="team-page">
    <page-header :page-title="pageTitle" :breadcrumbs="breadcrumbs" />

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <team-card :record="model" @updated="recordUpdated"></team-card>

          <card-block>
            <AdditionalFields
              :entity="additionalFieldsEntity"
              :values="model.fields || []"
              @update="onAdditionalFieldsUpdated"
              @save="storeRecord"
            />
          </card-block>
        </div>
        <div class="col-xl-8 order-xl-1">
          <div class="row">
            <div class="col-lg-6">
              <card-block class="border-0 bg-gradient-info">
                <div class="row">
                  <div class="col">
                    <h5
                      class="card-title text-uppercase text-muted mb-0 text-white"
                    >
                      Численность
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white">
                      {{ stats }}
                    </span>
                  </div>
                </div>
              </card-block>
            </div>
            <div class="col-lg-6">
              <card-block class="border-0 bg-gradient-danger">
                <div class="row">
                  <div class="col">
                    <h5
                      class="card-title text-uppercase text-muted mb-0 text-white"
                    >
                      Укомплектованность
                    </h5>
                    <span class="h2 font-weight-bold mb-0 text-white">
                      {{ statsShare }}
                    </span>
                  </div>
                </div>
              </card-block>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <card-block body-classes="p-0">
                <template v-slot:header>
                  <div class="row align-items-center">
                    <div class="col-8">
                      <h3 class="mb-0">
                        <span>Позиции в команде</span>
                      </h3>
                    </div>
                    <div class="col-4 text-right"></div>
                  </div>
                </template>

                <el-table
                  class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="model.positions"
                  empty-text="Нет позиций"
                  row-key="id"
                >
                  <el-table-column label="Позиция" prop="name" sortable>
                    <template v-slot="{ row }">
                      <div class="media align-items-center">
                        <router-link :to="getRoute(row)">
                          {{ row.name }}
                          <badge-block type="secondary"
                            >#{{ row.id }}</badge-block
                          >
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Отдел" prop="department" sortable>
                    <template v-slot="{ row }">
                      <div
                        class="media align-items-center"
                        v-if="row.department"
                      >
                        <router-link :to="buildDepartmentRoute(row.department)">
                          {{ row.department.name }}
                        </router-link>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="Сотрудник" prop="team" sortable>
                    <template v-slot="{ row }">
                      <div class="media align-items-center">
                        <router-link
                          :to="buildUserRoute(row.employee)"
                          v-if="row.employee"
                        >
                          {{ row.employee.name }}
                        </router-link>
                        <span v-else>Вакансия</span>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </card-block>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useBasePage } from '../../services/useBasePage'
import PageHeader from '../../components/PageHeader/PageHeader'
import CardBlock from '../../components/Cards/CardBlock'
import AdditionalFields from '../../components/AdditionalFields/AdditionalFields'
import TeamCard from './TeamCard'
import builders from '../../mixins/builders'

const config = {
  pageTitle: "Команда",
  cardTitle: "Информация о команде",
  successMessage: "Cохранено",
  storePrefix: "Teams",
  breadCrumbs: {
    "/teams": "Команды",
  },
  additionalFieldsEntity: "Team",
};

export default {
  name: "TeamPage",
  components: { TeamCard, AdditionalFields, CardBlock, PageHeader },
  mixins: [builders],
  setup() {
    const map = (data) => ({
      ...data,
    });

    return {
      ...useBasePage({ ...config, map }),
    };
  },
};
</script>

<style scoped></style>
