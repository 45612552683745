import { useRoute } from 'vue-router'
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'
import moment from 'moment'

export const useBasePage = ({
  storePrefix,
  pageTitle,
  cardTitle,
  successMessage,
  breadCrumbs,
  map,
  nameProp,
  additionalFieldsEntity,
}) => {
  const route = useRoute();
  // const router = useRouter();
  const toast = useToast();
  const store = useStore();

  const idParam = computed(() => route.params.id);
  const id = computed(() =>
    parseInt(idParam.value) == idParam.value ? parseInt(idParam.value) : null
  );
  const getOne = computed(store.getters[`${storePrefix}/getOne`]);

  const pageTitleRef = ref(pageTitle);
  const cardTitleRef = ref(cardTitle);
  const model = ref({
    id: id.value,
    name: null,
  });
  let originalModelStamp = null;
  const modelStamp = computed(() => JSON.stringify(model.value));
  const isChanged = computed(
    () => modelStamp.value && modelStamp.value !== originalModelStamp
  );
  const breadcrumbs = computed(() => {
    const breadcrumbs = Object.assign({}, breadCrumbs);
    breadcrumbs[route.path] = pageTitleRef.value;
    return breadcrumbs;
  });

  const employeesCount = computed(() =>
    undefined !== model.value.employees_count &&
    null !== model.value.employees_count
      ? parseInt(model.value.employees_count)
      : 0
  );
  const positionsCount = computed(() =>
    undefined !== model.value.positions_count &&
    null !== model.value.positions_count
      ? parseInt(model.value.positions_count)
      : 0
  );
  const staffing = computed(() => {
    if (!positionsCount.value) {
      return 0;
    }

    let number = Math.round(
      (employeesCount.value / positionsCount.value) * 100
    );
    if (0 > number) {
      return 0;
    }
    if (100 < number) {
      return 100;
    }
    return number;
  });
  const stats = computed(
    () => `${employeesCount.value}/${positionsCount.value}`
  );
  const statsShare = computed(() =>
    positionsCount.value ? `${staffing.value}%` : "–"
  );

  const formatDate = (date) => moment(date).format("LL");

  const getRoute = (row) => `/positions/${row.id}`;

  // const buildItemUrl = (id) => {
  //   const baseUrl = Object.keys(breadCrumbs)[0];
  //   return `${baseUrl}/${id}`;
  // };

  const fetchRecord = () => {
    store
      .dispatch(`${storePrefix}/getOne`, id.value)
      .then(({ data }) => {
        if (undefined !== data) {
          recordUpdated(data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const storeRecord = () => {
    console.log("storeRecord", model.value);
    store
      .dispatch(`${storePrefix}/updateOne`, model.value)
      .then(({ data }) => {
        recordUpdated(data);
        toast(successMessage);
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const additionalFields = ref({});

  const onAdditionalFieldsUpdated = (additionalFieldsValues) => {
    console.log("Updated additional fields", additionalFieldsValues);
    model.value.fields = additionalFieldsValues;
    // storeRecord();
  };

  const mapDataToModel = (data) => {
    model.value = {
      ...map(data),
      id: data.id,
      fields: data.fields,
    };
    additionalFields.value = data.fields || {};
    originalModelStamp = JSON.stringify(model.value);
    pageTitleRef.value = data[nameProp || "name"];
  };

  const recordUpdated = (record) => {
    mapDataToModel(record);
  };

  onMounted(() => {
    fetchRecord();
  });

  return {
    model,
    pageTitle: pageTitleRef,
    cardTitle: cardTitleRef,
    breadcrumbs,
    getOne,
    storeRecord,
    fetchRecord,
    mapDataToModel,
    isChanged,
    id,
    staffing,
    additionalFields,
    additionalFieldsEntity,
    onAdditionalFieldsUpdated,
    formatDate,
    getRoute,
    recordUpdated,
    stats,
    statsShare,
  };
};
