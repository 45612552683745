<template>
  <base-form-page
    class="position-form"
    :page-title="pageTitle"
    :card-title="cardTitle"
    :store-method="storeRecord"
    :is-changed="isChanged"
    :breadcrumbs="breadcrumbs"
    :id="id"
  >
    <div class="pl-lg-4">
      <div class="row">
        <div class="col">
          <base-input
            name="title"
            type="title"
            label="Название"
            placeholder="Название"
            v-model="model.title"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input
            name="name"
            type="name"
            label="Системное имя"
            placeholder="Системное имя"
            v-model="model.name"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input name="type" label="Тип">
            <el-select
              name="type"
              type="type"
              placeholder="Тип"
              no-data-text="Нет данных"
              v-model="model.type"
            >
              <el-option
                v-for="(item, index) of types"
                :key="index"
                :value="index"
                :label="item"
              />
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <base-input name="entity_type" label="Объект">
            <el-select
              name="entity_type"
              type="entity_type"
              placeholder="Объект"
              no-data-text="Нет данных"
              v-model="model.entity_type"
            >
              <el-option
                v-for="(item, index) of entities"
                :key="index"
                :value="index"
                :label="item"
              />
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row" v-if="model.type === 'FIELD_TYPE_OPTION'">
        <div class="col">
          <base-input name="options" label="Опции">
            <el-select
              name="options"
              type="options"
              placeholder="Опции"
              no-data-text="Добавьте новое значение"
              v-model="model.options"
              multiple
              filterable
              allow-create
            >
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col">
          <base-checkbox name="visible" v-model="model.visible">
            Отображать
          </base-checkbox>
        </div>
        <div class="col">
          <base-checkbox name="visible" v-model="model.required">
            Обязательное
          </base-checkbox>
        </div>
        <div class="col">
          <base-checkbox name="visible" v-model="model.readonly">
            Только для чтения
          </base-checkbox>
        </div>
        <div class="col">
          <base-checkbox name="visible" v-model="model.unique">
            Уникальное
          </base-checkbox>
        </div>
      </div>
    </div>
  </base-form-page>
</template>

<script>
import BaseFormPage from '../../components/BaseFormPage'
import { useBaseForm } from '@/services/useBaseForm'
import { useStore } from 'vuex'
import { ref } from 'vue'
import BaseCheckbox from '../../components/Inputs/BaseCheckbox'

const config = {
  createTitle: "Создание поля",
  editTitle: "Редактирование поля",
  storePrefix: "Fields",
  successMessage: "Поле сохранено",
  breadCrumbs: {
    "/fields": "Поля",
  },
};

export default {
  name: "FieldsForm",
  components: { BaseCheckbox, BaseFormPage },
  setup() {
    const store = useStore();

    const fields = ref([]);
    store.dispatch("Fields/fetchMany", { limit: 100 }).then(({ data }) => {
      fields.value = data;
    });

    const types = {
      FIELD_TYPE_TEXT: "Текст",
      FIELD_TYPE_NUMBER: "Число",
      FIELD_TYPE_CHECKBOX: "Флаг",
      FIELD_TYPE_OPTION: "Выбор",
    };

    const entities = {
      "App\\Models\\User": "Пользователь",
      "App\\Models\\Role": "Роль",
      "App\\Models\\Department": "Отдел",
      "App\\Models\\Team": "Команда",
    };

    const map = (data) => ({
      name: data.name,
      title: data.title,
      type: data.type,
      entity_type: data.entity_type,
      options: data.options,
      visible: data.is_visible,
      required: data.is_required,
      readonly: data.is_readonly,
      unique: data.is_unique,
    });

    return {
      ...useBaseForm({ ...config, map, nameProp: "title" }),
      fields,
      types,
      entities,
    };
  },
};
</script>

<style scoped></style>
