<template>
  <div class="card card-profile" v-if="user">
    <div class="row justify-content-center">
      <div class="col-lg-3 order-lg-2">
        <div class="card-profile-image">
          <a href="#">
            <avatar-block :user="user" class="big" />
          </a>
        </div>
      </div>
    </div>

    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between"></div>
    </div>

    <div class="card-body pt-0">
      <div class="text-center">
        <h5 class="h3">
          {{ user.name }}<span class="font-weight-light"></span>
        </h5>
        <div class="h5 font-weight-300" v-if="birthDate">
          {{ birthDate }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AvatarBlock from "../../../components/Avatar/AvatarBlock";
import moment from "moment";

export default {
  components: { AvatarBlock },
  props: {
    user: Object,
  },
  computed: {
    birthDate: (state) =>
      state.user && state.user.birth_date && null !== state.user.birth_date
        ? moment(state.user.birth_date).format("LL")
        : "",
  },
};
</script>
<style></style>
