import axios from "axios";
import methods from "./methods";
import MockAdapter from "axios-mock-adapter";
import Store from "@/store";
import Config from "@/config";

const mockRequests = process.env.VUA_APP_MOCK_API;
const apiBaseUrl = Config.api.baseUrl;
const getAccessToken = () => localStorage.getItem("access_token");

export const Api = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  },
});

Api.interceptors.request.use(function (config) {
  const token = getAccessToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

Api.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx
    return response;
  },
  function (error) {
    const status =
      undefined === error.response || undefined === error.response.status
        ? 500
        : error.response.status;
    const data =
      undefined === error.response || undefined === error.response.data
        ? {}
        : error.response.data;
    let message = "Ошибка";
    if (401 === status) {
      message = "Вы не авторизованы";
    }
    if (403 === status) {
      message = "Доступ запрещен";
    }
    if (404 === status) {
      message = "Страница не найдена";
    }
    if (422 === status) {
      message = "Ошибка валидации";
    }
    if (500 === status) {
      message = "Ошибка сервера";
    }

    // Any 401 response drops user data
    if (401 === status) {
      Store.commit("Auth/updateUserData", null);
    }

    return Promise.reject({
      status,
      message,
      data,
      response: error.response,
    });
  }
);

export const ApiMethods = methods;

// Mock requests while developing
const Mockery = new MockAdapter(Api, { delayResponse: 2000 });
if (!mockRequests) {
  Mockery.restore();
}
