<template>
  <base-page
    :page-title="title"
    :table-title="title"
    :fetch-method="fetch"
    :delete-method="deleteItem"
    :get-route-method="buildUserRoute"
    :create-route="createRoute"
  >
    <el-table-column
      label="Пользователь"
      min-width="310px"
      prop="name"
      sortable
    >
      <template v-slot="{ row }">
        <div class="media align-items-center">
          <router-link
            :to="buildUserRoute(row)"
            class="avatar rounded-circle mr-3"
          >
            <avatar-block :user="row" />
          </router-link>
          <div class="media-body">
            <span class="font-weight-600 name mb-0 text-sm">
              <router-link :to="buildUserRoute(row)">
                {{ row.name }}
              </router-link>
            </span>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Адес почты" prop="email" min-width="310px" sortable>
    </el-table-column>
    <el-table-column label="Отдел" min-width="200px" prop="department" sortable>
      <template v-slot="{ row }">
        <router-link
          :to="buildDepartmentRoute(row.department)"
          v-if="row.department"
        >
          {{ row.department.name }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column label="Команда" min-width="200px" prop="team" sortable>
      <template v-slot="{ row }">
        <router-link :to="buildTeamRoute(row.team)" v-if="row.team">
          {{ row.team.name }}
        </router-link>
      </template>
    </el-table-column>
    <el-table-column
      label="Ролей"
      prop="roles"
      min-width="100px"
      align="center"
    >
      <template v-slot="{ row }">
        <badge type="primary">
          <span class="status" v-if="row.roles">{{ row.roles.length }}</span>
        </badge>
      </template>
    </el-table-column>
    <el-table-column label="ID" prop="id" min-width="100px" sortable>
      <template v-slot="{ row }">
        <badge-block type="secondary">#{{ row.id }}</badge-block>
      </template>
    </el-table-column>
  </base-page>
</template>

<script>
import BasePage from '../../components/BasePage'
import AvatarBlock from '../../components/Avatar/AvatarBlock'
import builders from '../../mixins/builders'

export default {
  name: "UsersPage",
  components: { BasePage, AvatarBlock },
  mixins: [builders],
  data: () => ({
    title: "Пользователи",
    createRoute: "/users/create",
  }),
  methods: {
    deleteItem(id) {
      return this.$store.dispatch("Users/deleteOne", id);
    },
    fetch(params) {
      return this.$store.dispatch("Users/fetchMany", params);
    },
  },
};
</script>
