<template>
  <base-page
    :page-title="title"
    :table-title="title"
    :fetch-method="fetch"
    :fetch-one-method="fetchOne"
    :delete-method="deleteItem"
    :get-route-method="getRoute"
    :create-route="createRoute"
    :with-search="true"
    :with-children="true"
  >
    <el-table-column label="Поле" min-width="210px" prop="title" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center d-inline-block">
          <router-link :to="getRoute(row)">
            {{ row.title }}
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Системное имя" min-width="200px" prop="name" sortable></el-table-column>
    <el-table-column label="Тип" min-width="200px" prop="type_label" sortable></el-table-column>
    <el-table-column label="Объект" min-width="200px" prop="entity_type_label" sortable></el-table-column>
    <el-table-column label="Атрибуты" min-width="200px" prop="attrs" sortable>
      <template v-slot="{ row }">
        <badge-block type="primary" class="mr-1" v-if="row.is_required">Обязательное</badge-block>
        <badge-block type="primary" class="mr-1" v-if="row.is_visible">Видимое</badge-block>
        <badge-block type="primary" class="mr-1" v-if="row.is_readonly">Только чтение</badge-block>
        <badge-block type="primary" class="mr-1" v-if="row.is_unique">Уникальное</badge-block>
      </template>
    </el-table-column>

    <el-table-column label="ID" prop="id" min-width="100px" sortable>
      <template v-slot="{ row }">
        <badge-block type="secondary">#{{ row.id }}</badge-block>
      </template>
    </el-table-column>
  </base-page>
</template>

<script>
import BasePage from "../../components/BasePage";

export default {
  components: { BasePage },
  data: () => ({
    title: "Поля",
    createRoute: "/fields/create",
  }),
  methods: {
    deleteItem(id) {
      return this.$store.dispatch("Fields/deleteOne", id);
    },
    fetch(params) {
      params = params || {};
      params["only_top_level"] = 1;
      return this.$store.dispatch("Fields/fetchMany", params);
    },
    fetchOne(id) {
      return this.$store.dispatch("Fields/fetchOne", id);
    },
    getRoute(record) {
      return `/fields/${record.id}`;
    },
  },
};
</script>
