<template>
  <base-form-page
    class="position-form"
    :page-title="pageTitle"
    :card-title="cardTitle"
    :store-method="storeRecord"
    :is-changed="isChanged"
    :breadcrumbs="breadcrumbs"
    :id="id"
  >
    <div class="pl-lg-4">
      <div class="row">
        <div class="col">
          <base-input
            name="name"
            type="name"
            label="Название"
            placeholder="Название"
            v-model="model.name"
          >
          </base-input>
        </div>
      </div>
    </div>
  </base-form-page>
</template>

<script>
import BaseFormPage from '../../components/BaseFormPage'
import { useBaseForm } from '@/services/useBaseForm'

const config = {
  createTitle: "Создание статуса",
  editTitle: "Редактирование статуса",
  storePrefix: "Statuses",
  successMessage: "Статус сохранён",
  breadCrumbs: {
    "/statuses": "Статусы",
  },
};

export default {
  name: "StatusForm",
  components: { BaseFormPage },
  setup() {
    const map = (data) => ({
      name: data.name,
    });

    return useBaseForm({ ...config, map });
  },
};
</script>

<style scoped></style>
