<template>
  <base-form-page
    :bind="$attrs"
    class="position-form"
    :page-title="pageTitle"
    :card-title="cardTitle"
    :store-method="storeRecord"
    :is-changed="isChanged"
    :breadcrumbs="breadcrumbs"
    :id="id"
    :additional-fields-entity="additionalFieldsEntity"
    :additional-fields="additionalFields"
    :on-additional-fields-update-method="onAdditionalFieldsUpdated"
  >
    <div class="pl-lg-4">
      <div class="row">
        <div class="col form-col">
          <base-input
            name="name"
            type="name"
            label="Название"
            placeholder="Название"
            v-model="model.name"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col form-col">
          <team-select
            name="team_id"
            type="team_id"
            placeholder="Родительская команда"
            no-data-text="Нет команд"
            v-model="model.parent_id"
            clearable
          >
            <el-option
              v-for="item of teams"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </team-select>
        </div>
      </div>
      <div class="row">
        <div class="col form-col">
          <UserSelect
            v-model:user="model.supervisor_id"
            clearable
            placeholder="Руководитель команды"
            label="Руководитель команды"
          />
        </div>
      </div>
    </div>
  </base-form-page>
</template>

<script>
import BaseFormPage from '../../components/BaseFormPage'
import { useBaseForm } from '../../services/useBaseForm'
import { useStore } from 'vuex'
import { ref } from 'vue'
import TeamSelect from '../../components/TeamSelect/TeamSelect'
import UserSelect from '../../components/UserSelect/UserSelect'

const config = {
  createTitle: "Создание команды",
  editTitle: "Редактирование команды",
  storePrefix: "Teams",
  successMessage: "Команда сохранена",
  breadCrumbs: {
    "/teams": "Команды",
  },
  additionalFieldsEntity: "Team",
};

export default {
  name: "TeamForm",
  components: { TeamSelect, BaseFormPage, UserSelect },
  setup() {
    const store = useStore();

    const teams = ref([]);
    store.dispatch("Teams/fetchMany", { limit: 100 }).then(({ data }) => {
      teams.value = data;
    });

    const map = (data) => ({
      name: data.name,
      parent_id: data.parent_id,
    });

    return {
      ...useBaseForm({ ...config, map }),
      teams,
    };
  },
};
</script>

<style scoped></style>
