import { Api, ApiMethods } from "@/api";

export default {
  namespaced: true,
  state: () => ({
    isLoading: false,
    hasNetworkError: false,
    userData: null,
  }),
  getters: {
    isAuthenticated: (state) =>
      state.accessToken !== null && state.userData !== null,
    isLoading: (state) => state.isLoading,
    hasNetworkError: (state) => state.hasNetworkError && navigator.onLine,
    userData: (state) => state.userData,
    userId: (state) => (state.userData !== null ? state.userData.id : null),
    userName: (state) => (state.userData !== null ? state.userData.name : null),
    userPicture: () => null,
    accessToken: () => localStorage.getItem("access_token"),
  },
  actions: {
    login({ commit }, { email, password, rememberMe }) {
      commit("setIsLoading");
      console.log("login...");
      return new Promise((resolve, reject) => {
        Api.post(ApiMethods.login, { email, password, rememberMe })
          .then(({ data }) => {
            console.log("login", data);
            const accessToken = data.access_token || null;
            const userData = data.user_data || null;
            if (accessToken) {
              commit("updateAccessToken", accessToken);
            }
            if (userData) {
              commit("updateUserData", userData);
            }
            resolve();
          })
          .catch((errorDto) => {
            commit("updateAccessToken", null);
            commit("updateUserData", null);
            console.error("login", errorDto);
            reject(errorDto);
          })
          .finally(() => {
            commit("setIsLoaded");
          });
      });
    },
    logout({ commit }) {
      console.log("logout...");
      commit("removeAccessTokenAndUserData");
    },
    fetchUserData({ commit, getters }) {
      if (null !== getters.userData) {
        console.error("fetchUserData canceled: already fetched");
        return;
      }
      if (null === getters.accessToken) {
        console.error("fetchUserData canceled: have no access token");
        return;
      }
      commit("setIsLoading");
      console.log("fetchUserData...");
      return new Promise((resolve, reject) => {
        const url = ApiMethods.fetchUserData;
        Api.get(url)
          .then(({ data }) => {
            console.log("fetchUserData", data);
            commit("updateUserData", data.data);
            resolve(data);
          })
          .catch((errorDto) => {
            if (errorDto.status === 401) {
              commit("updateAccessToken", null);
              commit("updateUserData", null);
            }
            console.error("fetchUserData", errorDto);
            resolve(null);
            reject(errorDto);
          })
          .finally(() => {
            commit("setIsLoaded");
          });
      });
    },
  },
  mutations: {
    setIsLoading(state) {
      state.isLoading = true;
    },
    setIsLoaded(state) {
      state.isLoading = false;
    },
    setHasNetworkError(state) {
      state.hasNetworkError = true;
    },
    setHasNoNetworkError(state) {
      state.hasNetworkError = false;
    },
    updateUserData(state, userData) {
      state.userData = userData === null ? null : Object.assign({}, userData);
    },
    updateAccessToken(state, access_token) {
      if (access_token !== null) {
        localStorage.setItem("access_token", access_token);
      } else {
        localStorage.removeItem("access_token");
      }
    },
    removeAccessTokenAndUserData(state) {
      state.userData = null;
      localStorage.removeItem("access_token");
    },
  },
};
