export default {
  addToCollection(state, item) {
    if (undefined !== item && null !== item) {
      state.indexedCollection[item.id] = Object.assign({}, item);
    } else if (undefined !== state.indexedCollection[item.id]) {
      delete state.indexedCollection[item.id];
    }
    state.indexedCollection = Object.assign({}, state.indexedCollection);
    state.collection = Object.values(state.indexedCollection);
  },
  appendCollection(state, collection) {
    for (const item of collection) {
      state.indexedCollection[item.id] = item;
    }
    state.collection = Object.values(state.indexedCollection);
  },
  removeFromCollection(state, id) {
    delete state.indexedCollection[id];
    state.collection = Object.values(state.indexedCollection);
  },
  setIsLoading(state) {
    state.isLoading = true;
  },
  setIsLoaded(state) {
    state.isLoading = false;
  },
};
