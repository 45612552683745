<template>
  <base-form-page
    :bind="$attrs"
    class="position-form"
    :page-title="pageTitle"
    :card-title="cardTitle"
    :store-method="storeRecord"
    :is-changed="isChanged"
    :breadcrumbs="breadcrumbs"
    :id="id"
    :additional-fields-entity="additionalFieldsEntity"
    :additional-fields="additionalFields"
    :on-additional-fields-update-method="onAdditionalFieldsUpdated"
  >
    <div class="pl-lg-4">
      <div class="row">
        <div class="col form-col">
          <base-input
            name="name"
            type="name"
            label="Название"
            placeholder="Название"
            v-model="model.name"
          >
          </base-input>
        </div>
      </div>
      <div class="row">
        <div class="col form-col">
          <department-select
            name="department_id"
            label="Родительский отдел"
            placeholder="Родительский отдел"
            v-model="model.parent_id"
          ></department-select>
        </div>
      </div>
      <div class="row">
        <div class="col form-col">
          <UserSelect
            v-model:user="model.supervisor_id"
            clearable
            placeholder="Руководитель отдела"
            label="Руководитель отдела"
          />
        </div>
      </div>
    </div>
  </base-form-page>
</template>

<script>
import BaseFormPage from '../../components/BaseFormPage'
import { useBaseForm } from '../../services/useBaseForm'
import { useStore } from 'vuex'
import { ref } from 'vue'
import UserSelect from '../../components/UserSelect/UserSelect'
import DepartmentSelect from '../../components/DepartmentSelect/DepartmentSelect'

const config = {
  createTitle: "Создание отдела",
  editTitle: "Редактирование отдела",
  storePrefix: "Departments",
  successMessage: "Отдел сохранён",
  breadCrumbs: {
    "/departments": "Отделы",
  },
  additionalFieldsEntity: "Department",
};

export default {
  name: "DepartmentForm",
  components: { DepartmentSelect, BaseFormPage, UserSelect },
  setup() {
    const store = useStore();

    const departments = ref([]);
    store.dispatch("Departments/fetchMany", { limit: 100 }).then(({ data }) => {
      departments.value = data;
    });

    const map = (data) => ({
      name: data.name,
      parent_id: data.parent_id,
      supervisor_id: data.supervisor_id,
    });

    return {
      ...useBaseForm({ ...config, map }),
      departments,
    };
  },
};
</script>

<style scoped></style>
