export default {
  methods: {
    buildUserRoute(record) {
      return `/users/${record.id}`;
    },
    buildTeamRoute(record) {
      return `/teams/${record.id}`;
    },
    buildDepartmentRoute(record) {
      return `/departments/${record.id}`;
    },
  },
};
