<template>
  <base-form-page
    class="position-form"
    :page-title="pageTitle"
    :card-title="cardTitle"
    :store-method="storeRecord"
    :is-changed="isChanged"
    :breadcrumbs="breadcrumbs"
    :id="id"
    :additional-fields-entity="additionalFieldsEntity"
    :additional-fields="additionalFields"
    :on-additional-fields-update-method="onAdditionalFieldsUpdated"
  >
    <div class="pl-lg-4">
      <div class="row">
        <div class="col">
          <base-input
            name="name"
            type="name"
            label="Название"
            placeholder="Название"
            v-model="model.title"
          >
          </base-input>
        </div>
      </div>
    </div>
  </base-form-page>
</template>

<script>
import BaseFormPage from '../../components/BaseFormPage'
import { useBaseForm } from '@/services/useBaseForm'

const config = {
  createTitle: "Создание роли",
  editTitle: "Редактирование роли",
  storePrefix: "Roles",
  successMessage: "Роль сохранена",
  breadCrumbs: {
    "/roles": "Роли",
  },
  additionalFieldsEntity: "Role",
  nameProp: "title",
};

export default {
  name: "RoleForm",
  components: { BaseFormPage },
  setup() {
    const map = (data) => ({
      title: data.title,
    });

    return {
      ...useBaseForm({ ...config, map }),
    };
  },
};
</script>

<style scoped></style>
