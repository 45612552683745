import { useStore } from "vuex";
import { ref, watch } from "vue";
import { useVModel } from "@/services/useVModel";

export const useSuggest = (storePrefix, props) => {
  const store = useStore();
  const isLoading = ref(false);
  const options = ref([]);
  const valueState = useVModel(props, "user");

  watch(
    () => props.user,
    (value) => {
      if (value) {
        isLoading.value = true;
        store
          .dispatch(`${storePrefix}/fetchMany`, { users_ids: [value] })
          .then(({ data }) => {
            isLoading.value = false;
            options.value = data.map((item) => ({
              label: item.name,
              value: item.id,
            }));
          });
      }
    }
  );

  const remoteMethod = (query) => {
    if (query !== "") {
      isLoading.value = true;
      store.dispatch(`${storePrefix}/fetchMany`, { query }).then(({ data }) => {
        isLoading.value = false;
        options.value = data
          .map((item) => ({
            label: item.name,
            value: item.id,
          }));
      });
    } else {
      options.value = [];
    }
  };

  return {
    isLoading,
    valueState,
    options,
    remoteMethod,
  };
};
