<template>
  <base-input name="team_id" label="Отдел">
    <el-select
      v-model="value"
      no-data-text="Нет команд"
      v-bind="$attrs"
      value-key="id"
    >
      <el-option
        v-for="item of teams"
        :key="item.id"
        :value="item.id"
        :label="nameIndented(item.name, item.level, '&nbsp;&nbsp;')"
      />
    </el-select>
  </base-input>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "TeamSelect",
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    const teams = ref([]);
    store.dispatch("Teams/fetchMany", { limit: 100 }).then(({ data }) => {
      teams.value = data;
    });

    const value = computed({
      set(value) {
        emit("update:modelValue", value);
      },
      get() {
        return props.modelValue;
      },
    });

    const nameIndented = (name, level, string) => string.repeat(level) + name;

    return {
      teams,
      value,
      nameIndented,
    };
  },
};
</script>

<style scoped>

</style>
