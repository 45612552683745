export default {
  fetchUserData: "/user",
  login: "/login",
  departments: "/departments",
  fields: "/fields",
  positions: "/positions",
  roles: "/roles",
  statuses: "/statuses",
  teams: "/teams",
  users: "/users",
};
