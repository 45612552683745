<template>
  <card-block>
    <template v-slot:header>
      <div class="row align-items-center">
        <div class="col-8">
          <h3 class="mb-0">
            <!--<router-link v-if="!isSelfEditMode" element="button" to="/users" class=" btn btn-sm btn-base-button btn-secondary-outline mr-2">
              <i class="fa fa-caret-left" />
            </router-link>-->
            <span>{{ userComputed.name }}</span>
          </h3>
        </div>
        <div class="col-4 text-right">
          <base-button
            v-show="isChanged"
            type="primary"
            size="sm"
            @click="updateProfile"
            >Сохранить</base-button
          >
        </div>
      </div>
    </template>

    <form @submit.prevent="updateProfile">
      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-6 form-col">
            <base-input
              name="username"
              type="email"
              label="Логин для входа"
              placeholder="name@email.com"
              v-model="userValue.email"
              :disabled="true"
            >
            </base-input>
          </div>
          <div class="col-lg-6 form-col">
            <base-input
              name="phone"
              type="text"
              ref="password"
              placeholder=""
              v-model="userValue.password"
              :disabled="!isChangingPassword"
            >
              <template v-slot:label>
                <label class="form-control-label">
                  {{ isChangingPassword ? "Новый пароль" : "Пароль" }}
                </label>
                <div class="float-right">
                  <base-button
                    v-if="!isChangingPassword"
                    type="secondary"
                    outline
                    size="sm"
                    @click="changePassword"
                    >Изменить</base-button
                  >
                  <base-button
                    v-else
                    type="secondary"
                    outline
                    size="sm"
                    @click="cancelChangePassword"
                    >Отменить</base-button
                  >
                </div>
              </template>
            </base-input>
          </div>
        </div>
      </div>
      <hr class="my-4" />

      <h6 class="heading-small text-muted mb-4">Общая информация</h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col-lg-8 col-md-12">
            <div class="row">
              <div class="col form-col">
                <base-input
                  :disabled="isSaving"
                  name="lastname"
                  type="text"
                  label="Фамилия"
                  placeholder="Иванов"
                  v-model="userValue.last_name"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col form-col">
                <base-input
                  :disabled="isSaving"
                  name="firstname"
                  type="text"
                  label="Имя"
                  placeholder="Иван"
                  v-model="userValue.first_name"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col form-col">
                <base-input
                  :disabled="isSaving"
                  name="middle"
                  type="text"
                  label="Отчество"
                  placeholder="Иванович"
                  v-model="userValue.middle_name"
                >
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col form-col">
                <base-input
                  :disabled="isSaving"
                  name="alias"
                  type="alias"
                  label="Отображать как"
                  placeholder="Иван Иванов"
                  v-model="userValue.alias"
                />
              </div>
            </div>
            <div class="row">
              <div class="col form-col">
                <base-input
                  name="birth_date"
                  label="Дата рождения"
                  :disabled="isSaving"
                >
                  <template v-slot="{ focus, blur }">
                    <flat-pickr
                      @on-open="focus"
                      @on-close="blur"
                      class="form-control datepicker"
                      :config="dateConfig"
                      v-model="userValue.birth_date"
                      :disabled="isSaving"
                    />
                  </template>
                </base-input>
              </div>
            </div>
          </div>

          <div class="col form-col">
            <base-input label="Фото" :class="{ disabled: isSaving }">
              <dropzone-file-upload
                :multiple="false"
                :options="dropzoneConfig"
                @addedfile="onAddedFile"
              />
            </base-input>
          </div>
        </div>
      </div>
      <hr class="my-4" />

      <h6 class="heading-small text-muted mb-4">Контактная информация</h6>

      <div class="pl-lg-4">
        <div class="row">
          <div class="col form-col">
            <base-input
              :disabled="isSaving"
              name="email"
              type="email"
              label="Адрес электронной почты"
              placeholder="name@email.com"
              v-model="userValue.email"
            />
          </div>
        </div>
        <div class="row">
          <div class="col form-col">
            <base-input
              :disabled="isSaving"
              name="phone"
              type="text"
              label="Телефон"
              placeholder="+7 (999) 123-45-67"
              v-model="userValue.phone"
            />
          </div>
        </div>
        <div class="row">
          <div class="col form-col">
            <base-input
              :disabled="isSaving"
              name="telegram"
              type="text"
              label="Telegram"
              v-model="userValue.telegram_username"
            />
          </div>
        </div>
      </div>

      <hr class="my-4" />
      <h6 class="heading-small text-muted mb-4">Уведомления</h6>
      <div class="pl-lg-4">
        <div class="row">
          <div class="col form-col">
            <base-checkbox
              v-model="userValue.allow_email_notifications"
              class="mb-3"
              :disabled="isSaving"
            >
              Разрешить уведомления на почту
            </base-checkbox>
          </div>
        </div>
        <div class="row" v-if="userValue.telegram_username">
          <div class="col form-col">
            <base-checkbox
              v-model="userValue.allow_telegram_notifications"
              class="mb-3"
              :disabled="isSaving"
            >
              Разрешить уведомления в Телеграм
            </base-checkbox>
          </div>
        </div>
      </div>

      <AdditionalFields
        entity="User"
        title="Дополнительно"
        :disabled="isSaving"
        @update="onAdditionalFieldsUpdated"
        :values="userValue.fields"
        with-separator
        with-title
        edit-mode
      ></AdditionalFields>

      <div class="pl-lg-4 mt-4">
        <div class="row">
          <div class="col form-col">
            <base-button
              v-show="isChanged"
              type="primary"
              @click.prevent="updateProfile"
              :loading="isSaving"
              >Сохранить</base-button
            >
          </div>
        </div>
      </div>
    </form>
  </card-block>
</template>
<script>
import 'flatpickr/dist/flatpickr.css'
import { mapGetters } from 'vuex'
import DropzoneFileUpload from '../../../components/Inputs/DropzoneFileUpload'
import BaseButton from '../../../components/BaseButton'
import AdditionalFields from '../../../components/AdditionalFields/AdditionalFields'

export default {
  components: { AdditionalFields, BaseButton, DropzoneFileUpload },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isChangingPassword: false,
    isSaving: false,
    originalDataHash: null,
    userValue: {
      id: null,
      email: null,
      password: null,
      last_name: null,
      first_name: null,
      middle_name: null,
      alias: null,
      phone: null,
      birth_date: null,
      department_id: null,
      team_id: null,
      position_id: null,
      status: null,
      telegram_username: null,
      allow_telegram_notifications: false,
      allow_email_notifications: false,
      picture: null,
      picture_name: null,
      fields: {},
    },
    dateConfig: {
      allowInput: true,
      altFormat: "d.m.Y",
      altInput: true,
      dateFormat: "Y-m-d",
    },
    dropzoneConfig: {
      url: null,
      acceptedFile: "image/*",
      uploadMultiple: false,
    },
    departments: [],
    teams: [],
    statuses: [],
    positions: [],
    roles: [],
  }),
  computed: {
    ...mapGetters("Auth", ["userId", "userData"]),
    isSelfEditMode: (state) =>
      state.userComputed.id !== undefined && state.userComputed.id !== null
        ? state.userComputed.id === state.userId
        : false,
    userComputed: {
      get() {
        if (this.user && {} !== this.user) {
          return this.user;
        }
        return this.userValue;
      },
      set(value) {
        this.userValue = value;
      },
    },
    dataHash: (state) => JSON.stringify(state.userValue),
    isChanged: (state) => state.originalDataHash !== state.dataHash,
  },
  methods: {
    changePassword() {
      this.isChangingPassword = true;
      this.$refs.password.focus();
    },
    cancelChangePassword() {
      this.userValue.password = null;
      this.isChangingPassword = false;
    },
    updateProfile() {
      this.isSaving = true;
      this.$store
        .dispatch("Users/updateOne", this.userValue)
        .then(() => {
          this.$toast("Изменения сохранены");
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    onAddedFile(file) {
      const userValue = this.userValue;
      const reader = new FileReader();
      reader.onload = function () {
        userValue.picture = reader.result;
        userValue.picture_name = file.name;
      };
      reader.onerror = function (error) {
        console.log("Failed reading file contents", error);
      };
      reader.readAsDataURL(file);
    },
    onAdditionalFieldsUpdated(additionalFieldsValues) {
      this.userValue.fields = additionalFieldsValues;
    },
    mapActualUserDataToModel() {
      if (null === this.user) {
        return;
      }
      this.userValue.id = this.user.id;
      this.userValue.email = this.user.email;
      this.userValue.password = this.user.password;
      this.userValue.last_name = this.user.last_name;
      this.userValue.first_name = this.user.first_name;
      this.userValue.middle_name = this.user.middle_name;
      this.userValue.alias = this.user.alias;
      this.userValue.picture = null;
      this.userValue.picture_name = null;
      this.userValue.birth_date = this.user.birth_date;
      this.userValue.department_id = this.user.department_id;
      this.userValue.team_id = this.user.team_id;
      this.userValue.position_id = this.user.position_id;
      this.userValue.status = this.user.status;
      this.userValue.telegram_username = this.user.telegram_username;
      this.userValue.allow_telegram_notifications = this.user.allow_telegram_notifications;
      this.userValue.allow_email_notifications = this.user.allow_email_notifications;
      this.userValue.fields = this.user.fields;
      this.originalDataHash = JSON.stringify(this.userValue);
    },
  },
  watch: {
    user() {
      this.mapActualUserDataToModel();
    },
  },
  mounted() {
    this.mapActualUserDataToModel();
  },
};
</script>
<style></style>
