import { ApiMethods } from "@/api";
import { baseGetters, baseMutations, baseState, getBaseActions } from "../Base";

export default {
  namespaced: true,
  state: baseState,
  getters: baseGetters,
  actions: getBaseActions(ApiMethods.fields),
  mutations: baseMutations,
};
