<template>
  <hr class="my-4" v-if="withSeparator && fields.length > 0" />
  <h6
    class="heading-small text-muted mb-4"
    v-if="withTitle && fields.length > 0"
  >
    {{ title }}
  </h6>
  <div :class="listClasses" v-if="fields.length > 0">
    <div :class="itemClasses" v-for="field in fields" :key="field.id">
      <div :class="slotClasses" v-if="!editMode && !isEditable(field.id)">
        <label for="" class="form-control-label">{{ field.title }}</label>
        <a
          v-if="!editMode"
          class="btn btn-sm float-right"
          @click.prevent="editOneField(field.id)"
        >
          Редактировать
        </a>
        <div>{{ inputValues[field.name] }}</div>
      </div>
      <div
        v-else
        :class="slotClasses"
        @keyup.enter="emitSaveAction(field.id)"
        @keyup.esc="stopEditingOneField(field.id)"
      >
        <a
          v-if="!editMode"
          class="btn btn-sm float-right"
          @click.prevent="stopEditingOneField(field.id)"
        >
          Отмена
        </a>
        <a
          v-if="!editMode"
          class="btn btn-sm btn-primary float-right"
          @click.prevent="emitSaveAction(field.id)"
        >
          Сохранить
        </a>
        <base-input
          v-if="field.type === 'FIELD_TYPE_TEXT'"
          :label="field.title"
          v-model="inputValues[field.name]"
          :name="field.name"
          :disabled="isDisabled(field)"
          :required="field.is_required"
        ></base-input>
        <base-input
          v-if="field.type === 'FIELD_TYPE_NUMBER'"
          type="number"
          :label="field.title"
          v-model="inputValues[field.name]"
          :name="field.name"
          :disabled="isDisabled(field)"
          :required="field.is_required"
        ></base-input>
        <base-checkbox
          v-if="field.type === 'FIELD_TYPE_CHECKBOX'"
          :label="field.title"
          v-model="inputValues[field.name]"
          :name="field.name"
          :disabled="isDisabled(field)"
          :required="field.is_required"
        >
          {{ field.title }}
        </base-checkbox>
        <base-input
          v-if="field.type === 'FIELD_TYPE_OPTION'"
          :label="field.title"
          :name="field.name"
          :required="field.is_required"
        >
          <el-select
            v-model="inputValues[field.name]"
            :placeholder="field.title"
            :clearable="!field.is_required"
            :disabled="isDisabled(field)"
            :loading="isLoading"
            loading-text="Загрузка..."
            :required="field.required"
          >
            <el-option
              v-for="(option, index) in field.options"
              :key="index"
              :value="option"
              :label="option"
            ></el-option>
          </el-select>
        </base-input>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, defineEmits, ref, toRefs, watch } from 'vue'
import BaseInput from '../Inputs/BaseInput'
import BaseCheckbox from '../Inputs/BaseCheckbox'

export default {
  name: "AdditionalFields",
  components: { BaseCheckbox, BaseInput },
  props: {
    title: String,
    entity: String,
    withSeparator: Boolean,
    withTitle: Boolean,
    editMode: Boolean,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
    },
    listClasses: {
      type: String,
      default: "pl-lg-4",
    },
    itemClasses: {
      type: String,
      default: "row",
    },
    slotClasses: {
      type: String,
      default: "col form-col",
    },
  },
  setup(props, { emit }) {
    const entitiesMap = {
      User: "App\\Models\\User",
      Department: "App\\Models\\Department",
      Role: "App\\Models\\Role",
      Team: "App\\Models\\Team",
    };

    const fields = ref([]);
    const editable = ref([]);
    const entityType = computed(() => {
      return entitiesMap[props.entity];
    });
    const isDisabled = (field) => props.disabled || field.is_readonly;
    const isLoading = computed(() => props.loading || false);

    const inputValues = ref({});
    const { values } = toRefs(props);
    const populate = () => {
      for (const field in values.value) {
        inputValues.value[field] = values.value[field];
      }
    };

    const store = useStore();
    const fetch = () => {
      store
        .dispatch("Fields/fetchMany", { entity_types: [entityType.value] })
        .then(({ data }) => {
          fields.value = data.filter(
            (item) => item.entity_type === entityType.value && item.is_visible
          );
        });
    };

    const editOneField = (field) => {
      console.log("editOneField", { field, editable });
      editable.value.push(field);
    };

    const isEditable = (field) => {
      const elementIndex = editable.value.indexOf(field);
      return elementIndex >= 0;
    };

    const stopEditingOneField = (field) => {
      const elementIndex = editable.value.indexOf(field);
      editable.value.splice(elementIndex, 1);
    };

    const onUpdated = () => {
      const values = {};
      const fillableFields = fields.value
        .filter((field) => !field.is_readonly)
        .map((field) => field.name);

      for (const field in inputValues.value) {
        if (!fillableFields.includes(field)) {
          continue;
        }
        values[field] = inputValues.value[field];
      }

      emit("update", values);
    };

    const emitSaveAction = (field) => {
      stopEditingOneField(field);
      emit("save", field);
    };

    defineEmits(["update", "save"]);
    watch(inputValues, () => onUpdated(), { deep: true });
    watch(values, () => populate(), { deep: true });

    return {
      fields,
      inputValues,
      fetch,
      isDisabled,
      isLoading,
      editOneField,
      isEditable,
      stopEditingOneField,
      emitSaveAction,
    };
  },
  mounted: function () {
    this.fetch();
  },
};
</script>

<style scoped></style>
