<template>
  <div class="user-positions-component">
    <el-dialog v-model="isEditingPosition" title="Выбор позиции" width="30%">
      <div class="edit-department-status-position-block">
        <div class="row">
          <div class="col">
            <base-input name="department_id" label="Отдел">
              <el-select
                name="department_id"
                type="department_id"
                placeholder="Отдел"
                no-data-text="Нет отделов"
                v-model="department_id"
                clearable
              >
                <el-option
                  v-for="item of departments"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <base-input name="team_id" label="Команда">
              <el-select
                name="team_id"
                type="team_id"
                placeholder="Команда"
                no-data-text="Нет команд"
                v-model="team_id"
                clearable
              >
                <el-option
                  v-for="item of teams"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row" v-if="department_id || team_id">
          <div class="col">
            <base-input name="position_id" label="Позиция">
              <el-select
                name="position_id"
                type="position_id"
                placeholder="Позиция"
                no-data-text="Нет позиций"
                v-model="position_id"
                clearable
              >
                <el-option
                  v-for="item of positions"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </base-input>
          </div>
        </div>
        <div class="row" v-if="department_id && position_id">
          <div class="col">
            <base-input name="status_id" label="Статус">
              <el-select
                name="status_id"
                type="status_id"
                placeholder="Статус"
                no-data-text="Нет статусов"
                v-model="status_id"
              >
                <el-option
                  v-for="item of statuses"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                />
              </el-select>
            </base-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditingPosition = false">Отмена</el-button>
          <el-button
            type="primary"
            @click="updatePosition"
            :disabled="!allowSaving"
          >
            Сохранить
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="isEditingRoles" title="Выбор ролей" width="30%">
      <div class="edit-roles-block">
        <div class="row">
          <div class="col">
            <base-input name="user_roles" label="Роли">
              <el-select
                name="roles"
                type="roles"
                placeholder="Роли"
                no-data-text="Нет ролей"
                v-model="user_roles"
                clearable
                multiple
              >
                <el-option
                  v-for="item of roles"
                  :key="item.id"
                  :value="item.id"
                  :label="item.title"
                />
              </el-select>
            </base-input>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="isEditingRoles = false">Отмена</el-button>
          <el-button type="primary" @click="updateRoles">Сохранить</el-button>
        </span>
      </template>
    </el-dialog>

    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0">
              <span>Положение в организации</span>
            </h3>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="initPositionChangePopup"
            >
              Сменить
            </button>
          </div>
        </div>
      </div>
      <ul class="list-group list-group-flush list my--3">
        <li class="list-group-item p-4">
          <div class="row align-items-center">
            <div class="col ml--2 px-4">
              <h4 class="mb-0 font-weight-normal" v-if="position">
                <strong>{{ position }}</strong>
              </h4>
              <small>Позиция</small>
            </div>
          </div>
        </li>
        <li class="list-group-item p-4">
          <div class="row align-items-center">
            <div class="col ml--2 px-4">
              <h4 class="mb-0 font-weight-normal" v-if="status">
                <strong>{{ status }}</strong>
              </h4>
              <small>Статус</small>
            </div>
          </div>
        </li>
        <li class="list-group-item p-4">
          <div class="row align-items-center">
            <div class="col ml--2 px-4">
              <h4 class="mb-0 font-weight-normal" v-if="department">
                <router-link :to="departmentRoute"
                  ><strong>{{ department }}</strong></router-link
                >
              </h4>
              <small>Отдел</small>
            </div>
          </div>
        </li>
        <li class="list-group-item p-4">
          <div class="row align-items-center">
            <div class="col ml--2 px-4">
              <h4 class="mb-0 font-weight-normal" v-if="team">
                <router-link :to="teamRoute"
                  ><strong>{{ team }}</strong></router-link
                >
              </h4>
              <small>Команда</small>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="card mt-5">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <h3 class="mb-0">
              <span>Роли</span>
            </h3>
          </div>
          <div class="col-auto">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="initRolesChangePopup"
            >
              Сменить
            </button>
          </div>
        </div>
      </div>
      <ul class="list-group list-group-flush list my--3">
        <li
          class="list-group-item p-4"
          v-for="(role, index) in userRoles"
          :key="index"
        >
          <div class="row align-items-center">
            <div class="col ml--2 px-4">
              <small>
                {{ role }}
              </small>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import 'flatpickr/dist/flatpickr.css'
import { mapGetters } from 'vuex'
import builders from '../../../mixins/builders'

export default {
  mixins: [builders],
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isEditingPosition: false,
    isEditingRoles: false,
    department_id: null,
    team_id: null,
    position_id: null,
    status_id: null,
    user_roles: [],
    positions: [],
  }),
  computed: {
    ...mapGetters("Departments", {
      departments: "getCollection",
    }),
    ...mapGetters("Teams", {
      teams: "getCollection",
    }),
    ...mapGetters("Statuses", {
      statuses: "getCollection",
    }),
    ...mapGetters("Roles", {
      roles: "getCollection",
    }),
    allowSaving: (state) =>
      !state.department_id || (state.position_id && state.status_id),
    position: (state) =>
      state.user && state.user.position ? state.user.position.name : "",
    department: (state) =>
      state.user && state.user.department ? state.user.department.name : "",
    team: (state) =>
      state.user && state.user.team ? state.user.team.name : "",
    status: (state) =>
      state.user && state.user.status ? state.user.status.name : "",
    departmentRoute: (state) =>
      state.user.department
        ? state.buildDepartmentRoute(state.user.department)
        : null,
    teamRoute: (state) =>
      state.user.team ? state.buildTeamRoute(state.user.team) : null,
    userRoles: (state) =>
      state.user && state.user.roles
        ? state.user.roles.map((role) => role.title)
        : [],
  },
  methods: {
    initPositionChangePopup() {
      this.isEditingPosition = true;
    },
    initRolesChangePopup() {
      this.isEditingRoles = true;
    },
    fetchCollections() {
      this.$store.dispatch("Departments/fetchMany", { limit: 100 });
      this.$store.dispatch("Teams/fetchMany", { limit: 100 });
      this.$store.dispatch("Statuses/fetchMany", { limit: 100 });
      this.$store.dispatch("Roles/fetchMany", { limit: 100 });
    },
    fetchPositions() {
      const params = {};
      if (this.department_id) {
        params.departments_ids = [this.department_id];
      }
      if (this.team_id) {
        params.teams_ids = [this.team_id];
      }
      this.$store.dispatch("Positions/fetchMany", params).then((data) => {
        this.positions = data.data;
      });
    },
    mapActualUserDataToModel() {
      if (null === this.user) {
        return;
      }
      this.department_id =
        (this.user && this.user.department && this.user.department.id) || null;
      this.team_id = (this.user && this.user.team && this.user.team.id) || null;
      this.position_id =
        this.user && this.user.position && this.user.position.id
          ? this.user.position.id
          : null;
      this.status_id =
        this.user && this.user.status && this.user.status.id
          ? this.user.status.id
          : null;
      this.user_roles =
        this.user && this.user.roles
          ? this.user.roles.map((item) => item.id)
          : [];
    },
    updatePosition() {
      console.log("updatePosition...");
      this.$store
        .dispatch("Users/updatePosition", {
          user_id: this.user.id,
          position_id: this.position_id,
          status_id: this.status_id,
        })
        .then(() => {
          this.isEditingPosition = false;
          this.$toast("Изменения сохранены");
          this.mapActualUserDataToModel();
        })
        .catch((error) => {
          this.$toast(error.message);
        });
    },
    updateRoles() {
      console.log("updateRoles...");
      const params = {
        user_id: this.user.id,
        roles: this.user_roles,
      };
      this.$store
        .dispatch("Users/updateRoles", params)
        .then(() => {
          this.isEditingRoles = false;
          this.$toast("Изменения сохранены");
        })
        .catch((error) => {
          this.$toast(error.message);
        });
    },
  },
  watch: {
    user() {
      this.mapActualUserDataToModel();
    },
    department_id: {
      initial: true,
      handler: function () {
        this.fetchPositions();
      },
    },
    team_id: {
      initial: true,
      handler: function () {
        this.fetchPositions();
      },
    },
  },
  mounted() {
    this.mapActualUserDataToModel();
    this.fetchCollections();
  },
};
</script>
<style></style>
