<template>
  <div class="header bg-success pb-6">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row align-items-center py-4">
          <div class="col-lg-6 col-7">
            <h6 class="h2 text-white d-inline-block mb-0">
              <router-link :to="currentRoute">
                <h2 class="text-white">{{ pageTitle }}</h2>
              </router-link>
            </h6>
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4" v-if="breadcrumbs">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                  <li class="breadcrumb-item"><router-link to="/"><i class="fas fa-home"></i></router-link></li>
                  <router-link
                    v-for="(crumb, url) in breadcrumbs"
                    :key="url"
                    :to="url"
                    class="breadcrumb-item active"
                    style="display: inline-block"
                    v-slot="{ href, navigate, isExactActive }"
                  >
                    <a :class="{ active: isExactActive }" @click="navigate" :href="href">{{ crumb }}</a>
                  </router-link>
                </ol>
              </nav>
            </nav>
          </div>
          <div class="col-lg-6 col-5 text-right" v-if="createRoute">
            <router-link :to="createRoute" type="button" class="btn base-button btn-neutral btn-sm" >Создать</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    pageTitle: String,
    breadcrumbs: Object,
    createRoute: String,
  },
  computed: {
    currentRoute: (state) => state.$route.path,
  },
};
</script>

<style scoped>

</style>
