<template>
  <div>
    <page-header
      :page-title="pageTitle"
      :breadcrumbs="breadcrumbs"
      :create-route="createRoute"
    />

    <modal v-model:show="itemToDelete">
      <h5 class="modal-title" id="exampleModalLabel" v-if="itemToDelete">
        Удалить {{ itemToDelete.name }}?
      </h5>
      <template v-slot:footer>
        <base-button
          outline
          type="secondary"
          @click.prevent="itemToDelete = null"
          >Отменить</base-button
        >
        <base-button type="primary" @click.self="onDelete" :disabled="isLoading"
          >Да, удалить</base-button
        >
      </template>
    </modal>

    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col">
          <div class="card mb-0">
            <div class="card-header">
              <div class="row">
                <div class="col-6">
                  <h3 class="mb-0">{{ tableTitle }}</h3>
                </div>
                <div class="col-6 text-right" v-if="withSearch">
                  <el-input
                    class="table-search"
                    v-model="searchQuery"
                    size="mini"
                    placeholder="Поиск по таблице..."
                  />
                </div>
              </div>
            </div>

            <el-table
              class="table-responsive table-flush"
              header-row-class-name="thead-light"
              :data="filteredDataset"
              :empty-text="noDataText"
              row-key="id"
              :lazy="withChildren"
              :load="onLoad"
              :tree-props="{
                children: 'children',
                hasChildren: 'has_children',
              }"
            >
              <slot></slot>

              <el-table-column min-width="100px" align="right">
                <template v-slot="{ row }">
                  <div class="table-actions">
                    <el-tooltip content="Удалить?" placement="top">
                      <a
                        href="#!"
                        @click.prevent="itemToDelete = row"
                        class="table-action table-action-delete"
                        data-toggle="tooltip"
                        data-original-title="Удалить"
                      >
                        <i class="fas fa-trash"></i>
                      </a>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <div class="card-footer py-4 d-flex justify-content-end">
              <base-pagination
                v-model="currentPage"
                :total="pagination.totalCount"
                :is-loading="isLoading"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ElDropdown, ElDropdownItem, ElDropdownMenu, ElTable, ElTableColumn, } from 'element-plus'
import Modal from './ModalComponent'
import PageHeader from './PageHeader/PageHeader'

export default {
  components: {
    PageHeader,
    Modal,
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
  },
  props: {
    pageTitle: String,
    tableTitle: String,
    isLoading: Boolean,
    fetchMethod: Function,
    fetchOneMethod: Function,
    deleteMethod: Function,
    getRouteMethod: Function,
    createRoute: String,
    breadcrumbs: Object,
    withSearch: {
      type: Boolean,
      default: true,
    },
    searchProps: {
      type: Array,
      default: () => ["name"],
    },
    withChildren: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currentPage: 1,
    dataset: [],
    searchQuery: null,
    itemToDelete: null,
    isDeleting: false,
    pagination: {
      page: 1,
      perPage: 20,
      lastPage: 1,
      totalCount: 0,
    },
  }),
  computed: {
    noDataText: (state) => (state.isLoading ? "Загрузка..." : "Нет данных"),
    currentRoute: (state) => state.$route.path,
    filteredDataset: (state) =>
      state.dataset.filter((item) => {
        if (state.searchQuery === null) {
          return true;
        }
        const query = state.searchQuery.toLowerCase();
        for (const prop of state.searchProps) {
          if (item[prop].toLowerCase().includes(query)) {
            return true;
          }
        }
        return false;
      }),
  },
  methods: {
    onLoad(row, treeNode, resolve) {
      console.log({ row, treeNode, resolve });
      return this.fetchOne(row.id, resolve);
    },
    updatePagination({ offset, total_count }) {
      this.pagination.totalCount = total_count;
      this.pagination.lastPage = Math.ceil(
        total_count / this.pagination.perPage
      );
      this.pagination.page = Math.ceil(offset / this.pagination.perPage) + 1;
    },
    fetchOne(id, resolve) {
      this.$nprogress.start();
      return this.fetchOneMethod(id)
        .then(({ data }) => {
          resolve(data.children);
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.$nprogress.done();
        });
    },
    fetch(page = 1) {
      const params = {
        offset: this.pagination.perPage * (page - 1),
      };
      this.$nprogress.start();
      this.fetchMethod(params)
        .then((data) => {
          this.dataset = data.data;
          this.updatePagination(data.meta);
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {
          this.$nprogress.done();
        });
    },
    onDelete() {
      if (this.isDeleting) {
        return;
      }
      this.isDeleting = true;
      console.log("onDelete...");
      this.deleteMethod(this.itemToDelete.id)
        .then(() => {
          for (const i in this.dataset) {
            if (this.dataset[i].id === this.itemToDelete.id) {
              this.dataset.splice(i, 1);
            }
          }
        })
        .catch((error) => {
          this.$toast(error.message, {
            hideProgressBar: false,
            icon: false,
            closeButton: false,
            position: "top-center",
          });
        })
        .finally(() => {
          this.itemToDelete = null;
          this.isDeleting = false;
        });
    },
    getRecordRoute(record) {
      return this.getRouteMethod(record);
    },
  },
  watch: {
    currentPage(newPage) {
      this.fetch(newPage);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
