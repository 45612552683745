<template>
  <base-page
    :page-title="title"
    :table-title="title"
    :fetch-method="fetch"
    :delete-method="deleteItem"
    :get-route-method="getRoute"
    :create-route="createRoute"
    :search-props="['role', 'title']"
  >
    <el-table-column label="Роль" min-width="310px" prop="title" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center">
          <router-link :to="getRoute(row)">
            {{ row.title }}
            <badge-block type="secondary">#{{ row.id }}</badge-block>
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Системное имя" prop="role" min-width="300px" sortable></el-table-column>
  </base-page>
</template>

<script>
import BasePage from "../../components/BasePage";

export default {
  components: { BasePage },
  data: () => ({
    title: "Роли",
    createRoute: "/roles/create",
  }),
  methods: {
    deleteItem(id) {
      return this.$store.dispatch("Roles/deleteOne", id);
    },
    fetch(params) {
      return this.$store.dispatch("Roles/fetchMany", params);
    },
    getRoute(record) {
      return `/roles/${record.id}`;
    },
  },
};
</script>
