<template>
  <base-page
    :page-title="title"
    :table-title="title"
    :fetch-method="fetch"
    :delete-method="deleteItem"
    :get-route-method="getRoute"
    :create-route="createRoute"
  >
    <el-table-column label="Позиция" min-width="280px" prop="name" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center">
          <router-link :to="getRoute(row)">
            {{ row.name }}
            <badge-block type="secondary">#{{ row.id }}</badge-block>
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Отдел" min-width="280px" prop="department" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center" v-if="row.department">
          {{ row.department.name }}
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Команда" min-width="280px" prop="team" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center" v-if="row.team">
          {{ row.team.name }}
        </div>
      </template>
    </el-table-column>
  </base-page>
</template>

<script>
import BasePage from "../../components/BasePage";

export default {
  components: { BasePage },
  data: () => ({
    title: "Позиции",
    createRoute: "/positions/create",
  }),
  methods: {
    deleteItem(id) {
      return this.$store.dispatch("Positions/deleteOne", id);
    },
    fetch(params) {
      return this.$store.dispatch("Positions/fetchMany", params);
    },
    getRoute(record) {
      return `/positions/${record.id}`;
    },
  },
};
</script>
