<template>
  <div>
    <page-header
      page-title="Профиль пользователя"
      :breadcrumbs="breadcrumbs"
    ></page-header>
    <div class="container-fluid mt--6">
      <div class="row">
        <div class="col-xl-4 order-xl-2">
          <user-card :user="user"></user-card>
          <user-position :user="user"></user-position>
        </div>
        <div class="col-xl-8 order-xl-1">
          <edit-profile-form :user="user"></edit-profile-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import EditProfileForm from "./UserProfile/EditProfileForm.vue";
import UserCard from "./UserProfile/UserCard.vue";
import UserPosition from "./UserProfile/UserPosition.vue";
import {mapGetters} from "vuex";
import PageHeader from "../../components/PageHeader/PageHeader";

export default {
  components: {
    PageHeader,
    EditProfileForm,
    UserCard,
    UserPosition,
  },
  computed: {
    ...mapGetters("Auth", ["userId"]),
    ...mapGetters("Users", ["getOne"]),
    user: (state) => state.getOne(state.id),
    id: (state) => {
      if (state.$route.name === "UserPage") {
        return state.$route.params.id;
      }
      return state.userId;
    },
    breadcrumbs: (state) => {
      const breadcrumbs = {
        "/users": "Пользователи",
      };
      breadcrumbs[state.$route.path] = state.user ? state.user.name : "";
      return breadcrumbs;
    },
    dump: (state) => JSON.stringify(state.user, null, 2),
  },
  methods: {
    fetch() {
      this.$store.dispatch("Users/getOne", this.id);
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style>
.profile-header {
  /*background-image: url(/img/theme/profile-cover.jpg);*/
  background-size: cover;
  background-position: center top;
  min-height: 100px;
}
</style>
