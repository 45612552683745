<template>
  <base-page
    :page-title="title"
    :table-title="title"
    :fetch-method="fetch"
    :fetch-one-method="fetchOne"
    :delete-method="deleteItem"
    :get-route-method="getRoute"
    :create-route="createRoute"
    :with-search="true"
    :with-children="true"
  >
    <el-table-column label="Отдел" min-width="250px" prop="name" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center d-inline-block">
          <router-link :to="getRoute(row)">
            {{ row.name }}
            <badge-block type="secondary">#{{ row.id }}</badge-block>
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="Руководитель" min-width="200px" prop="supervisor" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center" v-if="row.supervisor">
          <avatar-block :user="row.supervisor" class="small mr-2"/>
          <span>
            {{ row.supervisor.name }}
          </span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="Сотрудников"
      min-width="120px"
      prop="employees_count"
      sortable
    >
      <template v-slot="{ row }">
        {{ row.employees_count || 0 }}/{{ row.positions_count || 0 }}
      </template>
    </el-table-column>
  </base-page>
</template>

<script>
import BasePage from "../../components/BasePage";
import AvatarBlock from "../../components/Avatar/AvatarBlock";
import BadgeBlock from "../../components/BadgeBlock";

export default {
  components: { BadgeBlock, AvatarBlock, BasePage },
  data: () => ({
    title: "Отделы",
    createRoute: "/departments/create",
  }),
  methods: {
    deleteItem(id) {
      return this.$store.dispatch("Departments/deleteOne", id);
    },
    fetch(params) {
      params = params || {};
      params["only_top_level"] = 1;
      return this.$store.dispatch("Departments/fetchMany", params);
    },
    fetchOne(id) {
      return this.$store.dispatch("Departments/fetchOne", id);
    },
    getRoute(record) {
      return `/departments/${record.id}`;
    },
  },
};
</script>
