import { Api, ApiMethods } from '@/api'
import { baseGetters, baseMutations, baseState, getBaseActions } from '../Base'

export default {
  namespaced: true,
  state: baseState,
  getters: baseGetters,
  actions: {
    ...getBaseActions(ApiMethods.users),
    updatePosition({ commit }, { user_id, position_id, status_id }) {
      commit("setIsLoading");
      console.log("updatePosition...", { user_id, position_id, status_id });
      return new Promise((resolve, reject) => {
        const url = `${ApiMethods.users}/${user_id}/position/${position_id}/${status_id}`,
          params = { position_id, status_id };
        Api.post(url, params)
          .then(({ data }) => {
            console.log("updatePosition", data);
            commit("addToCollection", data.data);
            resolve(data);
          })
          .catch((error) => {
            this.$toast(error.message);
            console.error("updatePosition", error);
            reject(error);
          })
          .finally(() => {
            commit("setIsLoaded");
          });
      });
    },
    updateRoles({ commit }, { user_id, roles }) {
      commit("setIsLoading");
      console.log("updateRoles...", { user_id, roles });
      return new Promise((resolve, reject) => {
        const url = `${ApiMethods.users}/${user_id}/roles`,
          params = { roles };
        Api.patch(url, params)
          .then(({ data }) => {
            console.log("updateRoles", data);
            commit("addToCollection", data.data);
            resolve(data);
          })
          .catch((error) => {
            this.$toast(error.message);
            console.error("updateRoles", error);
            reject(error);
          })
          .finally(() => {
            commit("setIsLoaded");
          });
      });
    },
  },
  mutations: baseMutations,
};
