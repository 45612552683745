import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ArgonDashboard from "./plugins/argon-dashboard";
import Store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

import Toast, { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import moment from "moment";
import store from "./store";

const appInstance = createApp(App).use(store);
appInstance.use(router);
appInstance.use(Toast, { containerClassName: "ct-notification" });
appInstance.use(ArgonDashboard);
appInstance.use(Store);
appInstance.mount("#app");

NProgress.configure({
  showSpinner: false,
});
appInstance.config.globalProperties.$nprogress = NProgress;

moment.locale("ru");
appInstance.config.globalProperties.$moment = moment;

const toast = useToast();
appInstance.config.globalProperties.$toast = toast;
