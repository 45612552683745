<template>
  <base-page
    :page-title="title"
    :table-title="title"
    :fetch-method="fetch"
    :fetch-one-method="fetchOne"
    :delete-method="deleteItem"
    :get-route-method="getRoute"
    :create-route="createRoute"
    :with-search="true"
    :with-children="true"
  >
    <el-table-column label="Команда" min-width="250px" prop="name" sortable>
      <template v-slot="{ row }">
        <div class="media align-items-center d-inline-block">
          <router-link :to="getRoute(row)">
            {{ row.name }}
            <badge-block type="secondary">#{{ row.id }}</badge-block>
          </router-link>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      label="Сотрудников"
      min-width="120px"
      prop="employees_count"
      sortable
    >
      <template v-slot="{ row }">
        {{ row.employees_count || 0 }}/{{ row.positions_count || 0 }}
      </template>
    </el-table-column>
  </base-page>
</template>

<script>
import BasePage from "../../components/BasePage";

export default {
  components: { BasePage },
  data: () => ({
    title: "Команды",
    createRoute: "/teams/create",
  }),
  methods: {
    deleteItem(id) {
      return this.$store.dispatch("Teams/deleteOne", id);
    },
    fetch(params) {
      params = params || {};
      params["only_top_level"] = 1;
      return this.$store.dispatch("Teams/fetchMany", params);
    },
    fetchOne(id) {
      return this.$store.dispatch("Teams/fetchOne", id);
    },
    getRoute(record) {
      return `/teams/${record.id}`;
    },
  },
};
</script>
