<template>
  <page-header
    v-if="!dialogMode"
    :page-title="pageTitle"
    :breadcrumbs="breadcrumbs"
    :create-route="createRoute"
  />

  <div class="container-fluid mt--6" v-if="!dialogMode">
    <div class="row">
      <div class="col">
        <card-block v-bind="$attrs">
          <template v-slot:header>
            <div class="row align-items-center">
              <div class="col-8">
                <h3 class="mb-0">
                  <span>{{ cardTitle }}</span>
                  <span class="badge badge-secondary ml-3" v-if="id"
                    >#{{ id }}</span
                  >
                </h3>
              </div>
              <div class="col-4 text-right">
                <base-button
                  v-show="isChanged"
                  type="primary"
                  size="sm"
                  @click="storeRecord"
                  >Сохранить</base-button
                >
              </div>
            </div>
          </template>
          <form @submit.prevent="storeRecord">
            <slot></slot>

            <AdditionalFields
              v-if="additionalFieldsEntity"
              :entity="additionalFieldsEntity"
              title="Дополнительно"
              @update="onAdditionalFieldsUpdateMethod"
              :values="additionalFields"
              with-separator
              with-title
              edit-mode
            ></AdditionalFields>

            <div class="pl-lg-4 mt-4">
              <div class="row">
                <div class="col">
                  <base-button
                    v-show="isChanged"
                    type="primary"
                    @click.prevent="storeRecord"
                  >
                    Сохранить
                  </base-button>
                </div>
              </div>
            </div>
          </form>
        </card-block>
      </div>
    </div>
  </div>

  <template v-else>
    <button
      v-show="!dialogOpened"
      class="btn btn-sm"
      @click.prevent="dialogOpened = !dialogOpened"
    >
      Редактировать
    </button>
    <el-dialog v-model="dialogOpened" :title="pageTitle" width="50%">
      <form @submit.prevent="storeRecord">
        <slot></slot>
      </form>
      <template #footer>
        <el-button @click.prevent="dialogOpened = false">Отмена</el-button>
        <el-button type="primary" @click.prevent="storeRecord"
          >Сохранить</el-button
        >
      </template>
    </el-dialog>
  </template>
</template>

<script>
import PageHeader from './PageHeader/PageHeader'
import AdditionalFields from './AdditionalFields/AdditionalFields'
import { ref } from 'vue'

export default {
  name: "BaseFormPage",
  components: { AdditionalFields, PageHeader },
  props: {
    id: {
      type: String,
      default: "",
    },
    cardTitle: {
      type: String,
      default: "",
    },
    pageTitle: {
      type: String,
      default: "",
    },
    createRoute: {
      type: String,
      default: "",
    },
    breadcrumbs: Object,
    storeMethod: {
      type: Function,
      default: () =>
        new Promise((resolve) => {
          resolve();
        }),
    },
    isChanged: {
      type: Boolean,
      default: false,
    },
    additionalFieldsEntity: String,
    additionalFields: Object,
    onAdditionalFieldsUpdateMethod: Function,
    dialogMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const dialogOpened = ref(false);

    const emitSaved = (record) => {
      emit("saved", record);
    };

    const storeRecord = () => {
      props.storeMethod((record) => {
        dialogOpened.value = false;
        emitSaved(record);
      });
    };

    return {
      dialogOpened,
      storeRecord,
    };
  },
};
</script>
