<template>
  <div class="card card-profile" v-if="record">
    <div class="card-header text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
      <div class="d-flex justify-content-between"></div>
    </div>

    <div
      class="card-body pt-0 d-flex flex-column align-content-center align-items-center"
    >
      <h5 class="h3 text-center">
        {{ record.name }}<span class="font-weight-light"></span>
      </h5>

      <div class="h5 font-weight-300 text-center" v-if="record.parent">
        {{ record.parent.name }}
      </div>

      <div class="h5 font-weight-300 text-center" v-if="record.supervisor">
        {{ record.supervisor.name }}
      </div>

      <div class="h5 font-weight-300 text-center" v-if="createdAt">
        {{ createdAt }}
      </div>

      <team-form dialog-mode @saved="emitUpdated" />
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import TeamForm from '../TeamForm/TeamForm'
import { computed, ref } from 'vue'

export default {
  components: {
    TeamForm,
  },
  props: {
    record: Object,
  },
  setup(props, { emit }) {
    const dialogTitle = "Редактирование команды";
    const isEditing = ref(true);

    const createdAt = computed(() => {
      return props.record &&
        props.record.created_at &&
        null !== props.record.created_at
        ? moment(props.record.created_at).format("LL")
        : "";
    });

    const emitUpdated = (record) => {
      emit("updated", record);
    };

    return {
      createdAt,
      dialogTitle,
      isEditing,
      emitUpdated,
    };
  },
};
</script>
<style></style>
