<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top border-bottom navbar-expand"
    :class="{ 'bg-success navbar-dark': type === 'default' }"
  >
    <div class="app-title">{{ companyName }}</div>

    <ul class="navbar-nav align-items-center ml-md-auto"></ul>
    <ul class="navbar-nav align-items-center ml-auto ml-md-0">
      <base-dropdown
        menu-on-right
        class="nav-item"
        tag="li"
        title-tag="a"
        title-classes="nav-link pr-0"
      >
        <template v-slot:title-container>
          <a href="#" class="nav-link pr-0" @click.prevent>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <avatar-block :user="userData" class="small" />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{
                  userName
                }}</span>
              </div>
            </div>
          </a>
        </template>

        <router-link to="/profile" class="dropdown-item">
          <i class="ni ni-single-02"></i>
          <span>Мой профиль</span>
        </router-link>
        <div class="dropdown-divider"></div>
        <a @click="logoutFromSystem" class="dropdown-item">
          <i class="ni ni-user-run"></i>
          <span>Выйти</span>
        </a>
      </base-dropdown>
    </ul>
  </base-nav>
</template>
<script>
import BaseNav from "../../components/Navbar/BaseNav";
import {mapActions, mapGetters} from "vuex";
import AvatarBlock from "../../components/Avatar/AvatarBlock";

export default {
  components: {
    AvatarBlock,
    BaseNav,
  },
  props: {
    type: {
      type: String,
      default: "default", // default|light
      description:
        "Look of the dashboard navbar. Default (Green) or light (gray)",
    },
  },
  computed: {
    ...mapGetters("Auth", ["userName", "userData"]),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    companyName: () => process.env.VUE_APP_COMPANY_NAME,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
    };
  },
  methods: {
    ...mapActions("Auth", ["logout"]),
    logoutFromSystem() {
      this.logout();
      this.$router.push("/login");
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>
