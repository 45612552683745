import { createRouter, createWebHistory } from 'vue-router'
import Store from '../store'

import DashboardLayout from '../views/Layout/DashboardLayout'
import AuthLayout from '../views/Pages/AuthLayout'

import DepartmentsPage from '../views/DepartmentsPage/DepartmentsPage.vue'
import DepartmentPage from '../views/DepartmentPage/DepartmentPage.vue'
import DepartmentForm from '../views/DepartmentForm/DepartmentForm'
import Login from '../views/Pages/LoginPage.vue'
import PositionsPage from '../views/PositionsPage/PositionsPage'
import PositionForm from '../views/PositionForm/PositionForm'
import RolesPage from '../views/RolesPage/RolesPage'
import RoleForm from '../views/RoleForm/RoleForm'
import StatusesPage from '../views/StatusesPage/StatusesPage'
import StatusForm from '../views/StatusForm/StatusForm'
import TeamsPage from '../views/TeamsPage/TeamsPage'
import TeamPage from '../views/TeamPage/TeamPage'
import TeamForm from '../views/TeamForm/TeamForm'
import FieldsPage from '../views/FieldsPage/FieldsPage'
import FieldForm from '../views/FieldForm/FieldForm'
import UserProfile from '../views/Pages/UserProfile.vue'
import UsersPage from '../views/UsersPage/UsersPage.vue'

const routes = [
  {
    path: "/",
    redirect: "/users",
    component: DashboardLayout,
    name: "Dashboards",
    meta: {
      restricted: true,
    },
    children: [
      {
        path: "/users/:id(\\d+)",
        name: "UserPage",
        components: { default: UserProfile },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/users",
        name: "UsersPage",
        components: { default: UsersPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/departments",
        name: "DepartmentsPage",
        components: { default: DepartmentsPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/departments/:id(\\d+)",
        name: "DepartmentPage",
        components: { default: DepartmentPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/departments/create",
        name: "DepartmentForm",
        components: { default: DepartmentForm },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/teams",
        name: "TeamsPage",
        components: { default: TeamsPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/teams/:id(\\d+)",
        name: "TeamPage",
        components: { default: TeamPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/teams/create",
        name: "TeamForm",
        components: { default: TeamForm },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/positions",
        name: "PositionsPage",
        components: { default: PositionsPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/positions/:id(\\d+)",
        name: "PositionForm",
        components: { default: PositionForm },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/roles",
        name: "RolesPage",
        components: { default: RolesPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/roles/:id(\\d+)",
        name: "RoleForm",
        components: { default: RoleForm },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/statuses",
        name: "StatusesPage",
        components: { default: StatusesPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/statuses/:id(\\d+)",
        name: "StatusForm",
        components: { default: StatusForm },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/fields",
        name: "FieldsPage",
        components: { default: FieldsPage },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/fields/:id(\\d+)",
        name: "FieldForm",
        components: { default: FieldForm },
        meta: {
          restricted: true,
        },
      },
      {
        path: "/profile",
        name: "Profile",
        components: { default: UserProfile },
        meta: {
          restricted: true,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/",
    component: AuthLayout,
    children: [
      {
        path: "/login",
        name: "Login",
        components: { default: Login },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.restricted)) {
    await Store.dispatch("Auth/fetchUserData");
    const isAuthenticated = Store.getters["Auth/isAuthenticated"];
    if (false === isAuthenticated) {
      next("/login");
      return;
    }
    if ("/login" === to.path) {
      next("/");
      return;
    }
  }
  next();
});

export default router;
