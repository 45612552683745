<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Пользователи',
            path: '/users',
            icon: 'fa fa-users text-primary',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Cтруктура',
            icon: 'fa fa-project-diagram text-primary',
          }"
        >
          <sidebar-item :link="{ name: 'Отделы', path: '/departments' }" />
          <sidebar-item :link="{ name: 'Команды', path: '/teams' }" />
          <sidebar-item :link="{ name: 'Позиции', path: '/positions' }" />
          <sidebar-item :link="{ name: 'Роли', path: '/roles' }" />
        </sidebar-item>
        <sidebar-item
          :link="{
            name: 'Настройки',
            icon: 'ni ni-settings-gear-65 text-primary',
          }"
        >
          <sidebar-item :link="{ name: 'Статусы', path: '/statuses' }" />
          <sidebar-item :link="{ name: 'Дополнительные поля', path: '/fields' }"/>
        </sidebar-item>
      </template>

      <template v-slot:links-after>
        <!--
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">Документация</h6>
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              target="_blank"
            >
              <i class="ni ni-spaceship"></i>
              <span class="nav-link-text">С чего начать</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              target="_blank"
            >
              <i class="fa fa-question"></i>
              <span class="nav-link-text">FAQ</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              href="#"
              target="_blank"
            >
              <i class="fa fa-youtube"></i>
              <span class="nav-link-text">Видеоуроки</span>
            </a>
          </li>
        </ul>
        -->
      </template>
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <router-view></router-view>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>
