<template>
  <base-input name="department_id" label="Отдел">
    <el-select v-model="value" no-data-text="Нет отделов" v-bind="$attrs" value-key="id">
      <el-option
        v-for="item of departments"
        :key="item.id"
        :value="item.id"
        :label="nameIndented(item.name, item.level, '&nbsp;&nbsp;')"
      />
    </el-select>
  </base-input>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";

export default {
  name: "DepartmentSelect",
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const store = useStore();
    const departments = ref([]);
    store.dispatch("Departments/fetchMany", { limit: 100 }).then(({ data }) => {
      departments.value = data;
    });

    const value = computed({
      set(value) {
        emit("update:modelValue", value);
      },
      get() {
        return props.modelValue;
      },
    });

    const nameIndented = (name, level, string) => string.repeat(level) + name;

    return {
      departments,
      value,
      nameIndented,
    };
  },
};
</script>
