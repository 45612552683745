import { Api } from "@/api";
import { CancelToken } from "axios";

export const getBaseActions = (baseUrl) => {
  const buildItemUrl = (id) => {
    return `${baseUrl}/${id}`;
  };

  const getOne = ({ dispatch, getters }, id) => {
    console.log("getOne...", id);
    const fetched = getters.getOne(id);
    if (fetched) {
      return new Promise((resolve) => {
        console.log("getOne: already fetched", fetched);
        resolve({ data: fetched });
      });
    }
    return dispatch("fetchOne", id);
  };

  const deleteOne = ({ commit }, id) => {
    console.log("deleteOne...", id);
    commit("setIsLoading");
    return new Promise((resolve, reject) => {
      const url = buildItemUrl(id);
      Api.delete(url)
        .then(({ data }) => {
          console.log("deleteOne", data);
          commit("removeFromCollection", id);
          resolve(data);
        })
        .catch((errorDto) => {
          console.error("deleteOne", errorDto);
          reject(errorDto);
        })
        .finally(() => {
          commit("setIsLoaded");
        });
    });
  };

  const fetchOne = ({ commit }, id) => {
    console.log("fetchOne...", id);
    commit("setIsLoading");
    return new Promise((resolve, reject) => {
      const url = buildItemUrl(id);
      Api.get(url)
        .then(({ data }) => {
          console.log("fetchOne", data);
          commit("addToCollection", data.data);
          resolve(data);
        })
        .catch((errorDto) => {
          console.error("fetchOne", errorDto);
          reject(errorDto);
        })
        .finally(() => {
          commit("setIsLoaded");
        });
    });
  };

  const fetchMany = ({ commit, state }, params) => {
    console.log("fetchMany...", params);
    if (state.cancelToken) {
      state.cancelToken.cancel("Cancel request: already fetching.");
      console.error("Cancel request: already fetching.");
    }

    commit("setIsLoading");

    state.cancelToken = CancelToken.source();
    return new Promise((resolve, reject) => {
      Api.get(baseUrl, { params, cancelToken: state.cancelToken.token })
        .then(({ data }) => {
          console.log("fetchMany", data);
          commit("appendCollection", data.data);
          resolve(data);
        })
        .catch((errorDto) => {
          console.error("fetchMany", errorDto);
          reject(errorDto);
        })
        .finally(() => {
          commit("setIsLoaded");
        });
    });
  };

  const updateOne = ({ commit }, item) => {
    console.log("update...", item);
    commit("setIsLoading");
    return new Promise((resolve, reject) => {
      let method, url;
      if (
        undefined === item ||
        null === item ||
        undefined === item.id ||
        null === item.id
      ) {
        url = baseUrl;
        method = "POST";
      } else {
        url = buildItemUrl(item.id);
        method = "PATCH";
      }
      Api({
        method,
        url,
        data: item,
      })
        .then(({ data }) => {
          console.log("update", data);
          commit("addToCollection", data.data);
          resolve(data);
        })
        .catch((errorDto) => {
          console.error("update", errorDto);
          reject(errorDto);
        })
        .finally(() => {
          commit("setIsLoaded");
        });
    });
  };

  return {
    getOne,
    deleteOne,
    fetchOne,
    fetchMany,
    updateOne,
  };
};
