<template>
  <div class="avatar-block" v-if="user" :style="style">
    <div class="letter">{{ letter }}</div>
  </div>
</template>

<script>
export default {
  name: "AvatarBlock",
  props: {
    user: Object,
    color: String,
  },
  data: () => ({
    colorValue: null,
  }),
  computed: {
    letter: (state) =>
      undefined !== state.user && undefined !== state.user.name
        ? state.user.name.charAt(0)
        : "",
    backgroundColor: {
      set(value) {
        this.colorValue = value;
      },
      get() {
        return this.colorValue ? this.colorValue : this.color;
      },
    },
    style: (state) => {
      const style = {
        backgroundColor: state.backgroundColor,
        outlineColor: state.backgroundColor,
      };

      if (undefined !== state.user && undefined !== state.user.picture) {
        style["backgroundImage"] = `url(${state.user.picture})`;
      }

      return style;
    },
  },
  methods: {
    generateColor() {
      this.colorValue = "#" + ((Math.random() * 0xffffff) << 0).toString(16);
    },
  },
  mounted() {
    this.generateColor();
  },
};
</script>
<style>
.avatar-block {
  background-color: gray;
  border-radius: 50%;
  border: 2px solid white;
  outline: none;
  height: 46px;
  width: 46px;
  min-height: 46px;
  min-width: 46px;
  max-height: 46px;
  max-width: 46px;
  display: flex;
  align-items: center;
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
}
.avatar-block.small {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
}
.avatar-block.big {
  height: 78px;
  width: 78px;
  min-height: 78px;
  min-width: 78px;
  max-height: 78px;
  max-width: 78px;
}
.avatar-block .letter {
  font-size: 140%;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
</style>
