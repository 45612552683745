import { useRoute, useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import { useStore } from 'vuex'
import { computed, onMounted, ref } from 'vue'

export const useBaseForm = ({
  createTitle,
  editTitle,
  storePrefix,
  successMessage,
  breadCrumbs,
  map,
  nameProp,
  additionalFieldsEntity,
}) => {
  const route = useRoute();
  const router = useRouter();
  const toast = useToast();
  const store = useStore();

  const idParam = computed(() => route.params.id);
  const id = computed(() =>
    parseInt(idParam.value) == idParam.value ? parseInt(idParam.value) : null
  );
  const isNewRecord = computed(() => id.value === null);
  const getOne = computed(store.getters[`${storePrefix}/getOne`]);

  const isEditing = ref(false);
  const dialogOpened = ref(false);
  const pageTitle = ref(isNewRecord.value ? createTitle : editTitle);
  const cardTitle = ref("Новая запись");
  const model = ref({
    id: id.value,
    name: null,
  });
  let originalModelStamp = null;
  const modelStamp = computed(() => JSON.stringify(model.value));
  const isChanged = computed(
    () => modelStamp.value && modelStamp.value !== originalModelStamp
  );
  const breadcrumbs = computed(() => {
    const breadcrumbs = Object.assign({}, breadCrumbs);
    breadcrumbs[route.path] = cardTitle.value;
    return breadcrumbs;
  });

  const buildItemUrl = (id) => {
    const baseUrl = Object.keys(breadCrumbs)[0];
    return `${baseUrl}/${id}`;
  };

  const fetchRecord = () => {
    if (isNewRecord.value) {
      return;
    }

    store
      .dispatch(`${storePrefix}/getOne`, id.value)
      .then(({ data }) => {
        if (undefined !== data) {
          mapDataToModel(data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const storeRecord = (successCallback, errorCallback) => {
    console.log("storeRecord", model.value);
    store
      .dispatch(`${storePrefix}/updateOne`, model.value)
      .then(({ data }) => {
        mapDataToModel(data);
        toast(successMessage);

        if (successCallback) {
          successCallback(data);
        }

        if (isNewRecord.value) {
          router.push(buildItemUrl(data.id));
        }
      })
      .catch((error) => {
        toast.error(error.message);
        if (errorCallback) {
          errorCallback(error);
        }
      });
  };

  const additionalFields = ref({});

  const mapDataToModel = (data) => {
    model.value = map(data);
    model.value.id = data.id;
    model.value.fields = data.fields;
    additionalFields.value = data.fields || {};
    originalModelStamp = JSON.stringify(model.value);
    cardTitle.value = data[nameProp || "name"];
  };

  const onAdditionalFieldsUpdated = (additionalFieldsValues) => {
    console.log("Updated additional fields", additionalFieldsValues);
    model.value.fields = additionalFieldsValues;
  };

  const closeDialog = () => {
    dialogOpened.value = false;
  };

  onMounted(() => {
    fetchRecord();
  });

  return {
    model,
    pageTitle,
    breadcrumbs,
    getOne,
    cardTitle,
    fetchRecord,
    storeRecord,
    mapDataToModel,
    isChanged,
    isNewRecord,
    id,
    additionalFields,
    additionalFieldsEntity,
    onAdditionalFieldsUpdated,
    closeDialog,
    isEditing,
    dialogOpened,
  };
};
