<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <div class="container">
        <div class="header-body text-center mb-3">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">{{ appName }}</h1>
              <p class="text-lead text-white">
                Добро пожаловать в панель управления пользователями Taskflow
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon
            class="fill-default"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-body px-lg-5 py-lg-5">
              <div class="text-center text-muted mb-4">
                <!-- <small>Введите данные для входа в систему</small>-->
              </div>
              <Form @submit="onSubmit" :validation-schema="schema">
                <base-input
                  alternative
                  name="email"
                  addon-left-icon="ni ni-email-83"
                  placeholder="Адрес электронной почты"
                  v-model="model.email"
                >
                </base-input>

                <base-input
                  alternative
                  name="password"
                  addon-left-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Пароль"
                  v-model="model.password"
                >
                </base-input>

                <!--<base-checkbox v-model="model.rememberMe">
                  Запомнить меня
                </base-checkbox>-->
                <div class="text-center">
                  <base-button
                    type="primary"
                    native-type="submit"
                    class="my-4"
                    :loading="isLoading"
                  >
                    Войти
                  </base-button>
                </div>
              </Form>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <!-- <router-link to="/dashboard" class="text-light"><small>Забыли пароль?</small></router-link>-->
            </div>
            <div class="col-6 text-right"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Form } from "vee-validate";
import * as Yup from "yup";
import { mapActions, mapGetters } from "vuex";
import Config from "@/config";
import { useToast } from "vue-toastification";

export default {
  components: {
    Form,
  },
  data: () => ({
    model: {
      email: "",
      password: "",
      rememberMe: false,
    },
    appName: Config.appName,
    schema: () =>
      Yup.object().shape({
        email: Yup.string().email().required().label("Адрес электронной почти"),
        password: Yup.string().min(5).required().label("Пароль"),
      }),
  }),
  computed: {
    ...mapGetters("Auth", ["isAuthenticated", "isLoading"]),
  },
  methods: {
    ...mapActions("Auth", ["login", "fetchUserData"]),
    redirect() {
      this.$router.push("/");
    },
    onSubmit() {
      this.login(this.model)
        .then(() => {
          this.fetchUserData().then(() => {
            this.redirect();
          });
        })
        .catch((error) => {
          const toast = useToast();
          if (401 === error.status) {
            error.message = "Неверный адрес электронной почты или пароль";
          }
          toast(error.message, {
            hideProgressBar: false,
            icon: false,
            closeButton: false,
            position: "top-center",
          });
        });
    },
  },
  mounted() {
    this.fetchUserData().then(() => {
      if (this.isAuthenticated) {
        this.redirect();
      }
    });
  },
};
</script>
