import BadgeBlock from "../components/BadgeBlock";
import BaseButton from "../components/BaseButton";
import BaseCheckbox from "../components/Inputs/BaseCheckbox";
import BaseDropdown from "../components/BaseDropdown";
import BaseHeader from "../components/BaseHeader";
import BaseInput from "../components/Inputs/BaseInput";
import BaseNav from "../components/Navbar/BaseNav";
import BasePagination from "../components/BasePagination";
import BaseProgress from "../components/BaseProgress";
import BaseRadio from "../components/Inputs/BaseRadio";
import BaseSwitch from "../components/BaseSwitch";
import CardBlock from "../components/Cards/CardBlock";
import StatsCard from "../components/Cards/StatsCard";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import VueFlatpickr from "vue-flatpickr-component";
import Flatpickr from "flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";
Flatpickr.localize(Russian);

const GlobalComponents = {
  install(app) {
    app.component("badge-block", BadgeBlock);
    app.component("base-button", BaseButton);
    app.component("base-checkbox", BaseCheckbox);
    app.component("base-dropdown", BaseDropdown);
    app.component("base-input", BaseInput);
    app.component("base-nav", BaseNav);
    app.component("base-header", BaseHeader);
    app.component("base-pagination", BasePagination);
    app.component("base-progress", BaseProgress);
    app.component("base-radio", BaseRadio);
    app.component("base-switch", BaseSwitch);
    app.component("card-block", CardBlock);
    app.component("stats-card", StatsCard);
    app.component("flat-pickr", VueFlatpickr);
    app.use(ElementPlus);
  },
};

export default GlobalComponents;
