<template>
  <base-input :label="label">
    <el-select
      v-bind="$attrs"
      v-model="valueState"
      filterable
      remote
      reserve-keyword
      loading-text="Поиск..."
      :remote-method="remoteMethod"
      :loading="isLoading"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </base-input>
</template>

<script>
import BaseInput from "../Inputs/BaseInput";
import {useSuggest} from "../../services/useSuggest";

export default {
  name: "UserSelect",
  components: { BaseInput },
  props: {
    user: String,
    label: String,
  },
  setup(props) {
    return useSuggest("Users", props);
  },
};
</script>

<style scoped></style>
